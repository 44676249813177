import React from 'react';
import "../Components/MenuTitle.css"

const MenuTitle = ({ title }) => {
  return (
    <header className='titleMain-container'>
      <h1 className='titleMain'>{title}</h1>
    </header>
  );
};

export default MenuTitle;
