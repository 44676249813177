import React, { useState } from 'react';
import ImageModal from './ImageModal';
import './ImageGallery.css';
import mainMenu1 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/1.jpg'; // Replace with your SVG/PNG file path
import mainMenu2 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/2.jpg'; // Replace with your SVG/PNG file path
import mainMenu3 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/3.jpg'; // Replace with your PDF file path
import mainMenu4 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/4.jpg'; // Replace with your SVG/PNG file path
import mainMenu5 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/5.jpg'; // Replace with your SVG/PNG file path
import mainMenu6 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/6.jpg'; // Replace with your SVG/PNG file path
import mainMenu7 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/7.jpg'; // Replace with your SVG/PNG file path
import mainMenu8 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/8.jpg'; // Replace with your SVG/PNG file path
import mainMenu9 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/8.jpg'; // Replace with your SVG/PNG file path
import blur1 from 'C:/Users/visiblyankerd/Desktop/GITHUB/restaurant-menu/src/assets/EGGFOOYOUGNBLURR.jpg'; // Replace with your SVG/PNG file path

function ImageGallery() {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const main = [
    { src: mainMenu1, alt: 'lo mein egg foo young', cover: blur1 },
    { src: mainMenu2, alt: 'Lunch Specials',cover: blur1 },
    { src: mainMenu3, alt: 'Lunch Specials (continued)',cover: blur1 },
    { src: mainMenu4, alt: 'Lunch Specials (continued-1)',cover: blur1 },
    { src: mainMenu5, alt: 'Lunch Specials (continued-2)',cover: blur1 },
    { src: mainMenu6, alt: 'Chefs Specialty Entree',cover: blur1 },
    { src: mainMenu7, alt: 'Beef Entrees',cover: blur1 },
    { src: mainMenu8, alt: 'Lunch Specials (continued-2)',cover: blur1 },
    { src: mainMenu9, alt: 'Shrimp Entrees',cover: blur1 }
  
  ];
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <div className="image-gallery">
      {main.map((image, index) => (
        <div key={index} className="image-container" onClick={() => openModal(image)}>
          <h1>{image.alt}</h1>
          <img src={image.cover} alt={image.alt} />
          
        </div>
      ))}
      {selectedImage && (
        <ImageModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          imageSrc={selectedImage.src}
          imageAlt={selectedImage.alt}
        />
      )}
    </div>
  );
}

export default ImageGallery;
