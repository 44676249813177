import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './ImageModal.css';

// Required for accessibility
Modal.setAppElement('#root');

function ImageModal({ isOpen, onRequestClose, imageSrc, imageAlt }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the background
    } else {
      document.body.style.overflow = ''; // Enable scrolling once modal closes
    }
    return () => {
      document.body.style.overflow = ''; // Clean up on unmount
    };
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
        <img src={imageSrc} alt={imageAlt} />
        <button onClick={onRequestClose} className="close-button">
          Close
        </button>
      </div>
    </Modal>
  );
}

export default ImageModal;
